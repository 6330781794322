import React, { ReactNode, useState } from "react";
import { Box, Typography, makeStyles, CircularProgress, useTheme, useMediaQuery, TablePagination } from "@material-ui/core";
import { Theme } from "src/theme";
import { Team__StaffPresets } from "@ollie-sports/core";
import { View } from "react-native-web";
import { getBifrost } from "../../services/bifrost.service";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { CoolTable, CoolRow } from "../../components/CoolTable";
import { compute, ObjectKeys } from "@ollie-sports/core";
import _ from "lodash";
import { Team__StaffTypes } from "@ollie-sports/models";
import { getTagColor } from "../../utils/getTagColor";
import { translate } from "@ollie-sports/i18n";
import { getTeamTagSecondaryText } from "../../components/Compose/constants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    backgroundColor: theme.palette.background.dark
  }
}));

export default function Directory() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box px={3} py={2} display="flex">
        <h1 className="text-2xl sm:text-4xl mt-4">{translate({ defaultMessage: "My Directory" })}</h1>
      </Box>
      <Box px={3} py={2} display="flex" flex={1}>
        <DirectoryTable />
      </Box>
    </div>
  );
}

function DirectoryTable() {
  const { data: directory } = getBifrost().directory__server__getAllDirectoryInfoForAccount.useServer({
    accountId: getCurrentUserAccountId()
  });

  if (!directory) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </View>
    );
  }

  let rows: CoolRow[] = _.orderBy(Object.values(directory.teams), a => a.name).map(team => {
    const roleOrder = ["staff", "guardians", "athletes"] as const;
    const prettyRoles = {
      staff: "Staff",
      guardians: "Guardians",
      athletes: "Players"
    };
    const rawGroups = compute.team.partitionAccountIdsByRole(team);
    let groups = ObjectKeys(rawGroups).reduce((acc, role) => {
      if (rawGroups[role].length) {
        acc.push({ prettyRole: prettyRoles[role], accountIds: rawGroups[role], sortVal: roleOrder.indexOf(role) });
      }
      return acc;
    }, [] as { prettyRole: string; accountIds: string[]; sortVal: number }[]);

    let topStaffRole = "";
    let topStaffName = "";

    const topStaffInfo = directory.topStaffInfoByTeamId[team.id];
    if (topStaffInfo) {
      const { account, role } = topStaffInfo;
      topStaffName = `${account.firstName} ${account.lastName}`;
      //TODO Translate web
      topStaffRole = Team__StaffPresets("en-us")[role].staffTitle;
    }

    groups = _.orderBy(groups, g => g.sortVal);
    const teamInfo = getTeamTagSecondaryText({
      gender: team.gender,
      birthYear: team.birthYear,
      topStaffInfo: { fullName: topStaffName }
    });
    const row: CoolRow = {
      id: team.id,
      rowStyle: {
        borderLeft: `10px solid ${getTagColor("teamTag")}`
      },
      cells: [
        team.name,
        "",
        {
          text: "Team Info:",
          disableSearch: true,
          getCellStyle: () => ({ ...styles.teamTopStaff, textAlign: "right", fontStyle: "italic" })
        },
        {
          text: teamInfo,
          textSearchScaleFactor: 0.91,
          getCellStyle: () => styles.teamTopStaff
        }
      ],
      subRows: groups.map(group => {
        const sortedAccounts = _.orderBy(
          group.accountIds.map(accountId => directory.accounts[accountId]),
          [
            a => {
              const staffRole = team.accounts[a.id]?.staffTitle;
              if (!staffRole) {
                return Infinity;
              }

              return [
                Team__StaffTypes.headCoach,
                Team__StaffTypes.assistantCoach,
                Team__StaffTypes.teamAdmin,
                Team__StaffTypes.staffMember
              ].indexOf(staffRole);
            },
            a => a.lastName
          ]
        );

        const subRow: CoolRow = {
          id: team.id + group.prettyRole,
          cells: [{ text: group.prettyRole, disableSearch: true }],
          rowStyle: {
            borderLeft: `10px solid ${getTagColor("teamTag")}`
          },
          disableSearch: true,
          subRows: sortedAccounts.map(account => {
            const staffRole = team.accounts[account.id]?.staffTitle;
            const prettyStaffRole = staffRole ? Team__StaffPresets("en-us")[staffRole].staffTitle : "";

            return {
              id: team.id + account.id,
              disableSearch: true,
              rowStyle: {
                borderLeft: `10px solid ${getTagColor("teamTag")}`
              },
              cells: [`${account.lastName}, ${account.firstName}`, prettyStaffRole, account.email, account.phoneNumber || ""]
            };
          })
        };

        return subRow;
      })
    };

    return row;
  });

  rows = rows.concat(
    _.orderBy(Object.values(directory.accounts), a => a.lastName).map(account => {
      return {
        rowStyle: {
          borderLeft: `10px solid ${getTagColor("account")}`
        },
        cells: [
          {
            text: account.firstName,
            getCellStyle: () => ({
              marginLeft: 12
            })
          },
          account.lastName,
          {
            text: account.email,
            disableSearch: true
          },
          {
            text: account.phoneNumber || "",
            disableSearch: true
          }
        ],
        id: account.id
      };
    })
  );

  return (
    <View style={{ flex: 1 }}>
      <CoolTable
        columnPercentWidths={[31, 18, 29, 22]}
        enableSearch={true}
        searchOpts={{
          placeholder: "Search all teams and people..."
        }}
        paginationOpts={{
          label: "Results per page:",
          rowsPerPageOptions: [100, 50, 25]
        }}
        rows={rows}
      />
    </View>
  );
}

const styles = {
  teamTopStaff: {
    fontSize: 10,
    marginTop: 6
  }
};
