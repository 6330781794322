import { Account, AccountId, Org, OrgId, PrettyPlayer, Team, TeamId, Team__StaffTypes } from "@ollie-sports/models";
import { computeTeamIdToHighestRankingStaff } from "../../compute/team.compute";
import { getServerHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";
import { directory__server__getAllDirectoryInfoForOrg } from "./directory__getAllDirectoryInfoForOrg";
import { directory__server__getAllDirectoryInfoForTeam } from "./directory__getAllDirectoryInfoForTeam";

export type UserDirectoryInfo = {
  orgs: Record<string, Org>;
  teams: Record<string, Team>;
  accounts: Record<string, Account>;
  prettyPlayers: Record<string, PrettyPlayer>;
  topStaffInfoByTeamId: Record<string, { account: Account; role: Team__StaffTypes } | undefined>;
};

export async function directory__server__getAllDirectoryInfoForAccount(p: { accountId: AccountId }): Promise<UserDirectoryInfo> {
  // SERVER_ONLY_TOGGLE

  const { getAppPgPool } = getServerHelpers();

  let orgs: Record<string, Org> = {};
  let teams: Record<string, Team> = {};
  let accounts: Record<string, Account> = {};
  let prettyPlayers: Record<string, PrettyPlayer> = {};

  const teamIdAndOrgIdQuery = `select team_id, org_id
    from v_team_accounts where account_id = '${p.accountId}'
      union
    select null as team_id, org_id
    from v_org_accounts where account_id = '${p.accountId}';`;

  const teamIdAndOrgIdQueryResult = (await getAppPgPool().query(teamIdAndOrgIdQuery)).rows;
  const uniqueOrgIds: Record<OrgId, true> = {};
  const uniqueTeamIdsWithoutAnOrg: Record<TeamId, true> = {};
  teamIdAndOrgIdQueryResult.map(result => {
    if (result.org_id) {
      uniqueOrgIds[result.org_id] = true;
    } else {
      uniqueTeamIdsWithoutAnOrg[result.team_id] = true;
    }
  });

  const orgData: Record<string, Org>[] = [];
  const teamData: Record<string, Team>[] = [];
  const accountData: Record<string, Account>[] = [];
  const prettyPlayerData: Record<string, PrettyPlayer>[] = [];

  await Promise.all([
    ...ObjectKeys(uniqueOrgIds).map(async orgId =>
      directory__server__getAllDirectoryInfoForOrg({ orgId }).then(data => {
        if (data) {
          orgData.push({ [data.org.id]: data.org });
          teamData.push(data.teams);
          accountData.push(data.accounts);
          prettyPlayerData.push(data.prettyPlayers);
        }
      })
    ),
    ...ObjectKeys(uniqueTeamIdsWithoutAnOrg).map(async teamId =>
      directory__server__getAllDirectoryInfoForTeam({ teamId }).then(data => {
        if (data) {
          teamData.push({ [data.team.id]: data.team });
          accountData.push(data.accounts);
          prettyPlayerData.push(data.prettyPlayers);
        }
      })
    )
  ]);

  orgData.map(a => {
    orgs = { ...orgs, ...a };
  });
  teamData.map(a => {
    teams = { ...teams, ...a };
  });
  accountData.map(a => {
    accounts = { ...accounts, ...a };
  });
  prettyPlayerData.map(a => {
    prettyPlayers = { ...prettyPlayers, ...a };
  });

  return {
    accounts,
    orgs,
    prettyPlayers,
    teams,
    topStaffInfoByTeamId: computeTeamIdToHighestRankingStaff({
      teams: Object.values(teams),
      getAccounts: accountIds => accountIds.map(id => accounts[id]).filter(a => !!a)
    })
  };
  // SERVER_ONLY_TOGGLE
}

directory__server__getAllDirectoryInfoForAccount.auth = () => {};

// i18n certified - complete
