import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { Account, Org, OrgId, Player, PlayerBundle, PrettyPlayer, Team, TeamId } from "@ollie-sports/models";
import { player } from "../../compute";
import { fetchPrettyPlayersFromPlayersAndPlayerBundlesAndAccounts } from "../../internal-utils/player-utils";
import { ObjectKeys } from "../../utils";

export async function directory__server__getAllDirectoryInfoForTeam(p: { teamId: TeamId }): Promise<{
  team: Team;
  accounts: Record<string, Account>;
  prettyPlayers: Record<string, PrettyPlayer>;
} | null> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const teamQuery = `select item
  from mirror_team
  where item->>'id' = '${p.teamId}';`;

  const accountsQuery = `select acc.item
  from (select distinct a.account_id
        from (select account_id from v_team_accounts where team_id = '${p.teamId}' ) a) a,
       mirror_account acc
  where a.account_id = acc.id;`;

  const playersQuery = `select p.item
  from v_team_exclude_deleted t,
       v_player_exclude_deleted p
  where p.item->>'teamId' = t.id
    and t.id = '${p.teamId}';`;

  const playerBundlesQuery = `select pb.item
  from v_team_exclude_deleted t,
       v_player_exclude_deleted p,
       v_player_bundle_exclude_deleted pb
  where p.item->>'teamId' = t.id
    and pb.id = p.item->>'linkedPlayerBundleId'
    and t.id = '${p.teamId}'`;

  const [team, accounts, players, playerBundles]: [
    Team,
    Record<string, Account>,
    Record<string, Player>,
    Record<string, PlayerBundle>
  ] = await Promise.all([
    (await getAppPgPool().query(teamQuery)).rows.map(result => result.item)[0],
    (
      await getAppPgPool().query(accountsQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, account) => {
        acc[account.id] = account;
        return acc;
      }, {} as Record<string, Account>),
    (
      await getAppPgPool().query(playersQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, pl) => {
        acc[pl.id] = pl;
        return acc;
      }, {} as Record<string, Player>),
    (
      await getAppPgPool().query(playerBundlesQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, bundle) => {
        acc[bundle.id] = bundle;
        return acc;
      }, {} as Record<string, PlayerBundle>)
  ]);

  const prettyPlayers = fetchPrettyPlayersFromPlayersAndPlayerBundlesAndAccounts(players, playerBundles, accounts);

  // return null;
  return {
    team,
    accounts,
    prettyPlayers
  };
  // SERVER_ONLY_TOGGLE
}

directory__server__getAllDirectoryInfoForTeam.auth = () => {};
// i18n certified - complete
