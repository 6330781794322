import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { Account, Org, OrgId, Player, PlayerBundle, PrettyPlayer, Team } from "@ollie-sports/models";
import { player } from "../../compute";
import { fetchPrettyPlayersFromPlayersAndPlayerBundlesAndAccounts } from "../../internal-utils/player-utils";
import { ObjectKeys } from "../../utils";

export async function directory__server__getAllDirectoryInfoForOrg(p: { orgId: OrgId }): Promise<{
  org: Org;
  teams: Record<string, Team>;
  accounts: Record<string, Account>;
  prettyPlayers: Record<string, PrettyPlayer>;
} | null> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const orgQuery = `select item
  from mirror_org
  where item->>'id' = '${p.orgId}';`;

  const teamsQuery = `select item
  from v_team_exclude_deleted
  where item->>'orgId' = '${p.orgId}';`;

  const accountsQuery = `select acc.item
  from (select distinct a.account_id
        from (select account_id from v_team_accounts where org_id = '${p.orgId}'
              union
              select account_id from v_org_accounts where org_id = '${p.orgId}') a) a,
       mirror_account acc
  where a.account_id = acc.id;`;

  const playersQuery = `select p.item
  from v_team_exclude_deleted t,
       v_player_exclude_deleted p
  where p.item->>'teamId' = t.id
    and t.item->>'orgId' = '${p.orgId}';`;

  const playerBundlesQuery = `select pb.item
  from v_team_exclude_deleted t,
       v_player_exclude_deleted p,
       v_player_bundle_exclude_deleted pb
  where p.item->>'teamId' = t.id
    and pb.id = p.item->>'linkedPlayerBundleId'
    and t.item->>'orgId' = '${p.orgId}';`;

  const [org, teams, accounts, players, playerBundles]: [
    Org,
    Record<string, Team>,
    Record<string, Account>,
    Record<string, Player>,
    Record<string, PlayerBundle>
  ] = await Promise.all([
    (await getAppPgPool().query(orgQuery)).rows.map(result => result.item)[0],
    (
      await getAppPgPool().query(teamsQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, team) => {
        acc[team.id] = team;
        return acc;
      }, {} as Record<string, Team>),
    (
      await getAppPgPool().query(accountsQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, account) => {
        acc[account.id] = account;
        return acc;
      }, {} as Record<string, Account>),
    (
      await getAppPgPool().query(playersQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, pl) => {
        acc[pl.id] = pl;
        return acc;
      }, {} as Record<string, Player>),
    (
      await getAppPgPool().query(playerBundlesQuery)
    ).rows
      .map(result => result.item)
      .reduce((acc, bundle) => {
        acc[bundle.id] = bundle;
        return acc;
      }, {} as Record<string, PlayerBundle>)
  ]);

  const prettyPlayers = fetchPrettyPlayersFromPlayersAndPlayerBundlesAndAccounts(players, playerBundles, accounts);

  // return null;
  return {
    accounts,
    org,
    prettyPlayers,
    teams
  };
  // SERVER_ONLY_TOGGLE
}

directory__server__getAllDirectoryInfoForOrg.auth = () => {};

// i18n certified - complete
